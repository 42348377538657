/* Carousel
 * Props:
 *   images: Required. The images to use. Must be in src/images/
 *   activeIdx: Optional (default=0).
 *     Index of the starting image.
 *     Can be changed to adjust active image.
 *   imageContainerStyle: Optional (default={}).
 *     Style object for the active image.
 *     Useful if you need to set constraints for irregular images.
 *     It's best to avoid having to use this.
 *   
 * Usage:
 *   <Carousel
 *     images={["dog.jpg", "pig.png", "bread.jpeg"]}
 *   />
 *
 *   <Carousel
 *     images={["dog.jpg", "pig.png", "bread.jpeg"]}
 *     activeIdx={2}
 *     imageContainerStyle={{width: 600px, height: 600px}}
 *   />
 */

import React, { useState } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import ImageDeck from "./image-deck"
import DynamicImage from "./dynamic-image"
import AccentedButton from "./accented-button"
import Modal from "./modal"
import ModalLink from "./modal-link"
import { filenameToText } from "../util/sluggo"

const ImageAndArrows = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
`

const ArrowButton = styled(AccentedButton)`
  height: 100px;
  width: 50px;
  line-height: 0;
  text-align: center;
  letter-spacing: 0;
  // Minimum border radius or hover/focus looks bad in chrome
  border-radius: 2px;
  font-size: 36px;
  color: var(--lightTextColor)
`

const LeftButton = styled(ArrowButton)`
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
`

const RightButton = styled(ArrowButton)`
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
`

const Thumbnails = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.nImages}, 64px);
  column-gap: 10px;
  justify-content: safe center;
  padding: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 10px;
  margin-right: 10px;
`

const Thumbnail = styled.button`
  width: 64px;
  height: 64px;
  border: none;
  padding: 0;
  background-color: var(--backgroundColor);
  margin: auto;

  &.active {
    border: none;
    box-shadow: 0 0 3px 3px var(--accentColor);
    background-color: white;
  }
`

export default function Carousel(props) {
  const [activeIdx, setActiveIdx] = useState(props.activeIdx)

  const identifier = uuidv4()
  const thumbnailId = idx => `carousel-${identifier}-thumbnail-${idx}`

  const selectThumbnail = idx => {
    const thumbnail = document.getElementById(thumbnailId(idx))
    console.log(`Scrolling thumbnail ${idx} into view`)
    thumbnail.scrollIntoView({behavior: "smooth", block: "nearest", inline: "center"})
    setActiveIdx(idx)
  }

  const thumbnails = Array.from(Array(props.images.length).keys()).map(idx =>
    <Thumbnail
      id={thumbnailId(idx)}
      key={idx}
      className={idx === activeIdx ? "active" : ""}
      data-qa={"CarouselThumbnail"}
      onClick={() => selectThumbnail(idx)}
      aria-label={"View image"}
    >
      <DynamicImage
        src={props.images[idx]}
        shouldShowBackground={false}
        data-qa={"CarouselThumbnailImage"}
      />
    </Thumbnail>
  )

  return (
    <div
      aria-label={"Image carousel. What fun!"}
      data-qa={props["data-qa"] || "Carousel"}
    >

      <Modal
        id={`carousel-${props.images[activeIdx]}`}
        contentLabel={filenameToText(props.images[activeIdx])}
        data-qa={"CarouselActiveImageModal"}
      >
        <DynamicImage
          src={props.images[activeIdx]}
          shouldShowBackground={false}
        />
      </Modal>

      <ImageAndArrows>
        <LeftButton
          aria-label={"View the previous image"}
          onClick={() => selectThumbnail(
            (props.images.length + activeIdx - 1) % props.images.length
          )}
          data-qa={"CarouselLeftButton"}
        >&#10092;</LeftButton>
        <ModalLink
          aria-label={"Enlarge the image"}
          modalId={`carousel-${props.images[activeIdx]}`}
        >
          <ImageDeck
            images={props.images}
            imageContainerStyle={props.imageContainerStyle}
            topIdx={activeIdx}
            fadeDuration={0.2}
            data-qa={"CarouselActiveImage"}
          />
        </ModalLink>
        <RightButton
          aria-label={"View the next image"}
          onClick={() => selectThumbnail(
            (activeIdx + 1) % props.images.length
          )}
          data-qa={"CarouselRightButton"}
        >&#10093;</RightButton>
      </ImageAndArrows>

      <Thumbnails nImages={props.images.length}>
        {thumbnails}
      </Thumbnails>

    </div>
  )


}

Carousel.defaultProps = {
  activeIdx: 0,
  imageContainerStyle: {},
}
